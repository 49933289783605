import React from "react";
import ReactDynamicImport from 'react-dynamic-import';
import { detectMob } from "@src/Helper";

const loader = () => {
  if (!detectMob()) {
    return (
      import('@components/ExmWallet/Withdraw')
    )
  } else {
    return (
      import('@components/ExmWallet/WithdrawMobile')
    )
  }
}

const Withdraw = ReactDynamicImport({ loader })

const WithdrawPage = () => <Withdraw />

export default WithdrawPage;
